import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/zasady_ochrany_osobnych_udajov/" className="sel">
              {" "}
              &gt; Zásady ochrany osobných údajov
            </a>
          </div>
          <div id="page_content">
            <h1 className="uc">Zásady ochrany osobných údajov</h1>
            <div className="wysiwyg">
              <div>
                <p>&nbsp;</p>
                <p>
                  <strong>Zásady ochrany osobných údajov (GDPR)</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                JNTL Consumer Health (Slovakia) s.r.o. sa stará o ochranu vašich osobných údajov a chce, aby ste sa oboznámili s tým, ako získavame, používame a zverejňujeme informácie. Tieto Zásady ochrany osobných údajov opisujú naše postupy v súvislosti s informáciami, ktoré získavame my alebo naši poskytovatelia služieb prostredníctvom webovej stránky alebo aplikácie (ďalej len „Služba“), ktorú prevádzkujeme a kontrolujeme, a z ktorej ste sa dostali k týmto Zásadám ochrany osobných údajov. Keď nám poskytnete svoje osobné údaje alebo použijete Službu, potvrdzujete, že ste si prečítali a pochopili tieto Zásady ochrany osobných údajov.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>POUŽÍVANIE MALOLETÝMI OSOBAMI</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Táto Služba nie je zameraná na osoby vo veku do  16 rokov a žiadame, aby tieto osoby neposkytovali osobné údaje prostredníctvom tejto Služby. Ak vaše dieťa poslalo osobné údaje a chcete požiadať, aby sa tieto osobné údaje odstránili, obráťte sa na nás, ako sa vysvetľuje v časti Obráťte sa na nás.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>ZHROMAŽĎOVANIE OSOBNÝCH ÚDAJOV</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Môžeme vás požiadať, aby ste poslali svoje osobné údaje, aby ste mohli využívať určité funkcie (napríklad doručovanie informácie o novinkách, tipy/pokyny alebo spracovanie objednávok) alebo sa zúčastňovať na konkrétnej aktivite (napríklad súťaží alebo iných propagačných akcií). Budeme vás informovať, aké údaje sa vyžadujú a aké údaje sú voliteľné.
                </p>
                <p>
                Osobné údaje, ktoré nám pošlete, môžeme skombinovať s inými osobnými údajmi, ktoré sme od vás získali, či už cez internet, alebo inak, napríklad históriu vašich nákupov. Môžeme ich tiež skombinovať s informáciami, ktoré o vás dostávame od iných pridružených spoločností Johnson &amp; Johnson. 
                </p>
                <p>
                Ak nám pošlete osobné údaje súvisiace s inou osobou, potvrdzujete tým, že máte oprávnenie tak urobiť a povoľujete nám, aby sme tieto údaje používali v súlade s týmito Zásadami ochrany osobných údajov.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>CITLIVÉ OSOBNÉ ÚDAJE</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Pokiaľ o to výslovne nepožiadame alebo vás nevyzveme, žiadame vás, aby ste nám neposielali ani nesprístupňovali žiadne citlivé osobné údaje (napr. rodné číslo, číslo občianskeho preukazu, informácie týkajúce sa rasového alebo etnického pôvodu, politických názorov, náboženstva alebo filozofického presvedčenia, zdravia, sexuálneho života alebo sexuálnej orientácie, trestného registra alebo členstva v odboroch, ani biometrické alebo genetické údaje) v Službe alebo prostredníctvom nej ani iným spôsobom.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                  AUTOMATICKÉ ZHROMAŽĎOVANIE OSOBNÝCH ÚDAJOV A ICH POUŽÍVANIE
                  </strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Pri vašom príchode na Službu my a naši poskytovatelia služieb môžeme automaticky zhromažďovaťVaše osobné údaje . Prečítajte si, prosím,&nbsp;
                  <a href="/zasady_pouzivania_suborov_cookies/" target="_blank">
                    Zásady súborov cookie
                  </a>
                  , kde nájdete podrobné informácie o súboroch cookie a iných technológiách na sledovanie, ktoré sa používajú v rámci Služieb. Pokiaľ nám platné právne predpisy neumožňujú inak, súbory cookie umiestňujeme až po získaní vášho súhlasu prostredníctvom banneru upozorňujúceho na zásady používania súborov cookie či prostredníctvom centra správy vašich nastavení. Zásady súborov cookie zahŕňajú informácie o tom, ako môžete tieto technológie znefunkčniť.  
                </p>
                <p>
                My a naši poskytovatelia služieb môžeme tiež automaticky zbierať a používať osobné údaje týmito spôsobmi:
                </p>
                <ul>
                  <li>
                    <strong>Prostredníctvom vášho prehliadača:</strong> Niektoré údaje zhromažďuje väčšina prehliadačov, ide o nasledovné údaje: vaša adresa na riadenie prístupu k médiám (Media Access Control, MAC), typ počítača (Windows alebo Mac), rozlíšenie obrazovky, tiež názov a verziu internetového prehliadača. Údaje otype vášho zariadenia a identifikačné údaje zhromažďujeme aj vtedy, ak do Služby vstupujete prostredníctvom mobilného zariadenia. Tieto údaje používame na to, aby sme zabezpečili správne fungovanie našich online Služieb.
                  </li>
                  <li>
                    <strong>IP adresa:</strong> Vaša IP adresa je číslo, ktoré vášmu počítaču automaticky priradí váš poskytovateľ internetu. IP adresa sa zistí a zaznamená automaticky do súborov denníka servera vždy, keď používateľ navštívi Službu spolu s časom návštevy a stránkami, ktoré navštívil. Zbieranie IP adries je štandardným postupom a vykonáva sa automaticky na mnohých internetových serveroch. IP adresy používame na účely výpočtu úrovní použitia Služby, diagnostiku problémov servera a správu Služby. Z vašej IP adresy vieme tiež odvodiť vaše približné umiestnenie.
                  </li>
                  <li>
                    <strong>Informácie o zariadeniach:</strong> Môžeme zhromažďovať aj údaje o vašom mobilnom zariadení, konkrétne: jedinečné identifikačné číslo zariadenia, a to za účelom analýzy spôsobu, akým Službu používate.
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                  <strong>AKO POUŽÍVAME A SPRÍSTUPŇUJEME INFORMÁCIE</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  &nbsp; Údaje, ktoré nám poskytnete, spracúvame tak, ako je uvedené v okamihu ich získania.   Prečítajte si časť nižšie s názvom „Možnosti voľby a prístup“ a zistite, ako môžete odstúpiť od niektorých spôsobov využívania našich Služieb  a ďalšie  užitočné informácie.
                </p>
                <p>
                Ak to vyžadujú príslušné právne predpisy, v čase získavania informácií si vyžiadame  váš súhlas so spracúvaním vašich osobných údajov.  Tiež v prípade potreby môžeme použiť informácie od vás alebo o vás, aby sme mohli plniť zmluvu, plniť požiadavky vyplývajúce zo zákonnej povinnosti (napríklad kvôli našim povinnostiam v oblasti farmakovigilancie) alebo na naše legitímne obchodné záujmy.  Naše legitímne obchodné záujmy môžu zahŕňať niektoré alebo všetky tieto ciele:
                </p>
                <ul>
                  <li>
                  odpovedať na vaše žiadosti o informácie a plniť vaše požiadavky, napríklad posielať dokumenty, ktoré požadujete, alebo e-mailové upozornenia,
                  </li>
                  <li>
                  posielať dôležité informácie súvisiace s našim vzájomným vzťahom alebo so službou, zmenami našich zmluvných podmienok a postupov a/alebo iné administratívne informácie, a
                  </li>
                  <li>
                  ďalšie obchodné účely, ako sú analýza údajov, audity, vývoj nových produktov, zlepšenie našej webovej stránky, zlepšenie našich produktov a služieb, zisťovanie trendov používania Služby, lepšie pochopenie vašich záujmov, aby sme vám mohli poskytovať lepšie služby, prispôsobenie vašich skúsenosti so Službou prezentáciou produktov a ponúk prispôsobených pre vás a zistenie účinnosti našich propagačných kampaní.
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                Údaje získané prostredníctvom Služby sprístupňujeme nasledovným kategóriám príjemcov:
                </p>
                <ul>
                  <li>
                  našim pridruženým spoločnostiam na účely opísané v Zásadách ochrany osobných údajov. Zoznam našich pridružených spoločností môžete nájsť na adrese&nbsp;
                    <a
                      href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                      target="_blank"
                    >
                      https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                    </a>
                    &nbsp;- získať prístup k nasledujúcim dokumentom Kenvue Komisii pre burzu cenných papierov.  JNTL Consumer Health (Slovakia) s.r.o. je stranou zodpovednou za správu spoločne používaných osobných údajov,
                  </li>
                  <li>
                  našim partnerom (tretím stranám), s ktorými  zdieľame vlastníctvo značky alebo sa spoločne podieľame na propagácii.
                  </li>
                  <li>
                  poskytovateľom služieb, ktorí sú tretími stranami a poskytujú nám nasledujúce službyhosting a moderating webových stránok, hosting mobilných aplikácií, analýzu údajov, spracovanie platieb, plnenie objednávok, poskytovanie infraštruktúry, služby IT, služby zákazníkom, služby elektronickej pošty a služby priamej doručovacej pošty (direct mail), audítorské služby a ďalšie obdobné služby, za účelom umožnenia poskytnutia Služby, a
                  </li>
                  <li>
                  v zmysle platnej legislatívy aj ďalším príjemcom  v prípade akejkoľvek reorganizácie, zlúčenia, predaja, vytvorenia spoločného podniku, postúpenia, prevodu alebo iného usporiadania nášho celého podniku alebo akejkoľvek jeho časti, majetku alebo akcií (tiež v súvislosti s akýmkoľvek konkurzným alebo podobným konaním).
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                Okrem toho používame a sprístupňujeme vaše osobné údaje, ak je to potrebné za účelom: a) dodržiavania právnych postupov alebo platnej legislatívy, ktorá môže zahŕňať zákony mimo vašej krajiny trvalého pobytu, b) podľa požiadaviek platnej legislatívy reagovať na žiadosti verejných a vládnych orgánov, ktoré môžu zahŕňať orgány mimo vašej krajiny trvalého pobytu; (c) uplatnenia našich zmluvné podmienky, a (d) chrániť naše práva, súkromie, bezpečnosť alebo majetok a/alebo naše pridružené spoločnosti, vás alebo iné osoby.  Vaše osobné údaje môžeme tiež použiť a sprístupniť inými spôsobmi, ak získame váš súhlas.
                </p>
                <p>
                Používame a sprístupňujeme osobné údaje, ktoré zhromažďujeme automaticky, ako sa opisuje vyššie v časti „Automatické zbieranie osobných údajov a ich používanie údajov“.
                </p>
                <p>
                Ak to umožňuje platná legislatíva, môžeme okrem toho použiť a sprístupniť údaje, ktoré nie sú vo forme, ktorá by umožňovala identifikáciu konkrétnej osoby, na akýkoľvek účel. Ak spojíme údaje vo forme, ktorá neumožňuje identifikáciu konkrétnej osoby, s identifikovateľnými údajmi (napríklad spojenie vášho mena s vašou geografickou polohou), považujeme spojené údaje za osobné údaje, kým pretrváva spojenie.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>MOŽNOSTI VOĽBY A PRÍSTUP</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Vaše možnosti voľby týkajúce sa nášho spracúvania a sprístupňovania vašich osobných údajov</strong>
                </p>
                <p>
                Udelený súhlas so spracúvaním Vašich osobných údajov môžete kedykoľvek odvolať, a to tak, že sa na nás obrátite prostredníctvom &nbsp;
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
                    target="_blank"
                  >
                    formulára tu
                  </a>
                  &nbsp;prípadne tak, že navštívite Službu a aktualizujete svoj internetový profil. Dávame Vám do pozornosti možnosť voľby týkajúcu sa nášho používania a zverejňovania vašich osobných údajov na marketingové účely. Môžete si vybrať z:
                </p>
                <ul>
                  <li>
                   <strong>Dostávania marketingových správ:</strong> Ak už časom nebudete chcieť od nás dostávať marketingové správy, môžete sa rozhodnúť, že sa odhlásite z ich odoberania v budúcnosti tak, že sa na nás obrátite prostredníctvom &nbsp;
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
                      target="_blank"
                    >
                      formulára tu.
                    </a>
                    &nbsp;Vo svojej požiadavke uveďte svoje meno, určte formy marketingových správ, ktoré už nechcete dostávať, a uveďte adresy, na ktoré sa posielajú. Napríklad, ak už od nás nechcete dostávať marketingové e-maily alebo priame maily, povedzte nám to a uveďte svoje meno a e-mail alebo poštovú adresu. Okrem toho sa môžete odhlásiť od dostávania našich marketingových e-mailov, keď budete postupovať podľa pokynov na zrušenie odberu uvedených v každej takejto správe.
                  </li>
                  <li>
                    <strong>Doručovanie pripomienok:</strong> Ak už časom nebudete chcieť od nás dostávať pripomienky medicínskej povahy, môžete sa rozhodnúť, že sa odhlásite z ich odoberania v budúcnosti tak, že sa na nás obrátite prostredníctvom &nbsp;
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
                      target="_blank"
                    >
                      formulára tu.
                    </a>
                    Vo vašej odpovedi nám uveďte svoje meno a e-mailovú adresu alebo telefónne číslo, kam dostávate od nás pripomienky.
                  </li>
                  <li>
                  	<strong>Naše zdieľanie vašich osobných údajov s pridruženými 
                      spoločnosťami a partnermi (tretie strany):</strong> 
                      Ak ste predtým súhlasili s prijímaním marketingových správ od našich pridružených spoločností alebo partnerov (tretích strán), môžete tento súhlas odvolať tak, že už ďalej nebudeme s týmito stranami zdieľať vaše osobné údaje na účely ich priameho marketingu. Odvolanie súhlasu uskutočníte tak, že sa na nás obrátite prostredníctvom &nbsp;
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
                      target="_blank"
                    >
                      formulára tu.
                    </a>
                    Vo svojej správe nám uveďte, že už viac nemáme zdieľať vaše osobné údaje s našimi pridruženými spoločnosťami a/alebo partnermi (tretími stranami) na ich marketingové účely, a vložte svoje meno a e-mailovú adresu.
                  </li>
                </ul>
                <p>
                Budeme sa snažiť vyhovieť vašim požiadavkám hneď, ako to bude možné. Upozorňujeme, že ak odhlásite, ako sme opísali vyššie, nebudeme môcť odstrániť vaše osobné údaje z databáz našich pridružených spoločností, s ktorými sme už zdieľali vaše osobné údaje (t. j. odhlásenie bude platiť od dátumu, keď zapracujeme vašu žiadosť o odhlásenie/zablokovanie). Budeme sa však usilovať informovať našich pridružených spoločností o vašej žiadosti. Upozorňujeme tiež, že ak sa odhlásite od prijímania správ týkajúcich sa marketingu, môžeme vám stále posielať dôležité transakčné a administratívne správy, prijímanie ktorých nie je možné zrušiť.
                </p>
                <p>&nbsp;</p>
                <p>
                <strong>Ako môžete získať prístup k vašim osobným údajom, meniť ich alebo vymazať </strong>
                </p>
                <p>
                Ak by ste chceli svoje osobné údaje skontrolovať, opraviť, aktualizovať, obmedziť alebo vymazať, alebo by ste chceli požiadať o elektronickú kópiu svojich osobných údajov na účely ich prenosu inej spoločnosti (pokiaľ sú tieto práva poskytnuté vám podľa platného zákona), kontaktujte nás prostredníctvom&nbsp;
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
                    target="_blank"
                  >
                   formulára tu.
                  </a>
                  Na vašu žiadosť odpovieme v primeranej lehote, najneskôr do jedného mesiaca od jej prijatia. Ak určité okolnosti spôsobia akékoľvek oneskorenie našej odpovede, budete okamžite informovaní a uvedieme vám dátum, kedy odpovieme. 
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>PRENOS CEZ HRANICE</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Vaše osobné údaje sa môžu uložiť a spracovať v akejkoľvek krajine, v ktorej máme zariadenia alebo poskytovateľov služieb, a používaním našej Služby alebo poskytnutím svojho súhlasu (ak to vyžaduje zákon) súhlasíte s prenosom osobných údajov do krajín mimo vašej krajiny trvalého bydliska vrátane Spojených štátov, ktoré môžu poskytovať odlišné pravidlá ochrany ochrana osobných údajov ako vo vašej krajine. Avšak pri ich prenose osobných údajov do našich pridružených spoločností alebo tretích strán v iných krajinách platia primerané zmluvné a iné opatrenia na ich ochranu.
                </p>
                <p>
                Niektoré krajiny mimo Európskeho hospodárskeho priestoru (EHP) považuje Európska komisia za krajiny, ktoré poskytujú primeranú úroveň ochrany osobných údajov podľa štandardov EHP (úplný zoznam týchto krajín je k dispozícii&nbsp;
                  <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_sk"
                    target="_blank"
                  >
                    tu.&nbsp;
                  </a>
                  Pri prevodoch z EHP do krajín, ktoré Európska komisia nepovažuje za také, ktoré zabezpečujú primerané opatrenia vrátane zabezpečenia toho, aby bol príjemca viazaný <strong>štandardnými zmluvnými doložkami EÚ</strong> na ochranu vašich osobných údajov. Kópiu týchto opatrení môžete získať tak, že sa obrátite na našu zodpovednú osobu v súlade s pokynmi v časti <em>„Obráťte sa na nás“</em> nižšie.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>BEZPEČNOSŤ</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Snažíme sa používať primerané organizačné, technické a administratívne opatrenia určené na ochranu osobných údajov, ktoré sú pod našou kontrolou. Bohužiaľ, žiadny prenos údajov cez internet alebo systém na ukladanie dát nebude možný so zárukou 100% bezpečnosti. Ak máte dôvod si myslieť, že vaša interakcia s nami už nie je bezpečná (napríklad vtedy, ak máte pocit, že bezpečnosť niektorého účtu, ktorý máte u nás, je ohrozená), ihneď nám to oznámte v súlade s časťou <em>„Obráťte sa na nás“</em> nižšie.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>OBDOBIE UCHOVÁVANIA</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Vaše osobné informácie budeme uchovávať tak dlho, ako to bude potrebné alebo povolené, vzhľadom na účel (účely), na ktoré boli získané. Kritériá používané na stanovenie našich období na uchovávanie zahŕňajú: (i) dĺžku trvania vzťahu s vami a poskytovania Služby, (ii) či existuje právny záväzok, ktorý sa na nás vzťahuje, a (iii) či z pohľadu nášho právneho postavenia je uchovávanie vhodné (napríklad v súvislosti s platnými predpismi o premlčacích lehotách, súdnych sporoch alebo úradných vyšetrovaniach).
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>STRÁNKY A SLUŽBY TRETÍCH STRÁN</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Vaše osobné informácie budeme uchovávať tak dlho, ako to bude potrebné alebo povolené, vzhľadom na účel (účely), na ktoré boli získané. Tieto Zásady ochrany osobných údajov, sa netýkajú a s výnimkou prípadov, kedy to vyplýva z predpisov v oblasti ochrany osobných údajov, nenesieme žiadnu zodpovednosť za ochranu Vášho súkromia, Vašich osobných údajov a za konanie tretích strán vrátane tretích strán, ktoré prevádzkujú akúkoľvek webovú stránku alebo internetovú službu (vrátane , nie však výlučne akúkoľvek aplikáciu), ktoré sú k dispozícii prostredníctvom tejto stránky alebo na ktoré táto stránka odkazuje, a my za ne nenesieme zodpovednosť. Dostupnosť alebo zahrnutie odkazu na akúkoľvek takúto stránku alebo internetovú službu či aplikáciu na stránkeneznamená, že ich my alebo naše pridružené spoločnosti schvaľujeme.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>OBRÁŤTE SA NA NÁS</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                JNTL Consumer Health (Slovakia) s.r.o. so sídlom na adrese Karadžičova 8/A, Bratislava, 821 08 je spoločnosť zodpovedná za zhromažďovanie, používanie a sprístupňovanie osobných údajov podľa týchto Zásad ochrany osobných údajov.
                </p>
                <p>
                Ak budete mať otázky týkajúce sa týchto Zásad ochrany osobných údajov, obráťte sa na nás prostredníctvom &nbsp;
                  <a
                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
                    target="_blank"
                  >
                    formulára tu 
                  </a>
                  , alebo napíšte na túto adresu:
                </p>
                <p>JNTL Consumer Health (Slovakia) s.r.o.</p>
                <p>Karadžičova 8/A,</p>
                <p>Bratislava, 821 08</p>
                <p>&nbsp;</p>
                <p>
                Môžete sa tiež obrátiť na zodpovednú osobu pre ochranu osobných údajov, ktorá je zodpovedná za Vašu krajinu alebo región, podľa toho, čo je relevantné, prostredníctvom adresy <a href="mailto:emeaprivacy@kenvue.com">emeaprivacy@kenvue.com.</a> 
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>PODANIE SŤAŽNOSTI U REGULÁTORA</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Môžete podať sťažnosť u orgánu dohľadu prislúchajúcemu vašej krajine alebo regiónu. Kliknite&nbsp; 
                  <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm">
                  sem
                  </a>
                  , zobrazia sa kontaktné údaje týchto úradov.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>
                    AKTUALIZÁCIE TÝCHTO ZÁSAD NA OCHRANU OSOBNÝCH ÚDAJOV
                  </strong>
                </p>
                <p>&nbsp;</p>
                <p>
                Tieto Zásady na ochranu osobných údajov sa môžu zmeniť. Všetky zmeny týchto Zásad ochrany osobných údajov nadobudnú účinnosť až potom, keď sa revidované Zásady ochrany osobných údajov zverejnia v Službe. Vaše používanie Služby po týchto zmenách znamená, že akceptujete upravené Zásady ochrany osobných údajov. Tieto zásady sa naposledy aktualizovali 02.06.2023.
                </p>
              </div>
              <p>&nbsp;</p>
            </div>
          </div>
          <Footer></Footer>{" "}
          
        </div>
      </div>
    );
  }
}

export default Page;
